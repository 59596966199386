import { InputLabel } from '@mui/material';
import React, { useEffect } from 'react';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';


export const SunEditorField = ({ fielddata, value, setValue, getValues, errors, clearErrors }) => {

  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue !== undefined)
      setValue(fielddata.name, fielddata.defaultValue);
  }, [])

  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <SunEditor
        setOptions={{
          height: fielddata.editorWidth,
          buttonList: fielddata.buttonListOptions
        }}
        setAllPlugins={true}
        buttonList={buttonList.formatting}
        defaultValue={fielddata.defaultValue !== undefined && fielddata.defaultValue}
        onChange={(e) => {
          setValue(fielddata.name, e);
        }}
        placeholder={fielddata.placeholder}
        onSave={(e) => {
          setValue(fielddata.name, e);
        }}
      />
    </div>
  )
}
