import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import BackendHeader from "../Common/BackendHeader/BackendHeader";
import BackendFooter from "../Common/BackendFooter/BackendFooter";
import "./BackendLayout.css";

const BackendLayout = () => {
  const location = useLocation();

  if (location.pathname === '/' || location.pathname === '/login'){   
    document.body.className += ' noPadding';
  }

  return (
    <>
        <div className="mainContent p30TB">
        
        {(location.pathname === '/calendar-management-createEvent' || location.pathname === '/calendar-management-available-slots' || location.pathname === '/calendar-management/available-slots-sync-google/:code/:secrect' || location.pathname === '/calendar-management-event-listing') &&
        <BackendHeader />
        }
          
            <div className="pageMain">
                <Outlet />
            </div>

            {(location.pathname === '/calendar-management-createEvent' || location.pathname === '/calendar-management-available-slots' || location.pathname === '/calendar-management/available-slots-sync-google/:code/:secrect' || location.pathname === '/calendar-management-event-listing') &&
            <BackendFooter/>
        }

            
        </div>

        
    </>
  );
};

export default BackendLayout;
