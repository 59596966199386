import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import React, { useState } from 'react'

export const PasswordField = ({ fielddata, value, setValue, getValues, errors, clearErrors, setError }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl >
        {fielddata.label && <InputLabel >{fielddata.label}</InputLabel>}
        <OutlinedInput
          type={showPassword ? 'text' : 'password'}
          value={getValues(fielddata.name) ? getValues(fielddata.name) : ""}
          onChange={(e) => {
            setValue(fielddata.name, e.target.value);
            clearErrors(fielddata.name); // Clearing errors on focus or change
          }}
          /////////////////////////////// For Confirm Password /////////////////////////
          onBlur={(e) => {
            if (fielddata.name === "confirmPassword" && e.target.value !== "" && getValues("password") !== undefined && getValues("password") !== e.target.value) {
              console.log('e++++++++++++++++++++++++++', e.target.value, getValues("password"));
              setError(fielddata.name, { type: "errorMessageConfirmPassword" });
            }
          }}
          label={fielddata.label}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}