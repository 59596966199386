import React, { useEffect } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';

export const TimePickerField = ({ fielddata, value, setValue, getValues, errors, clearErrors, setError }) => {
  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue) {
      setValue(fielddata.name, fielddata.defaultValue);
    }
  }, []);

  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label={fielddata.label}
            value={getValues(fielddata.name) ? getValues(fielddata.name) : null}//////////// current time set as default value ////////////////
            onChange={(e) => {
              console.log(typeof (e), e);
              if (e === null) {
                setError(fielddata.name, { type: "required" }, { shouldFocus: true });
                return;
              }
              setValue(fielddata.name, new Date(e.toString()).getTime());
              clearErrors(fielddata.name);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        {/* ERROR */}
        {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
      </FormControl>
    </div>
  )
}
