import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useDispatch } from "react-redux";
import { fetchCalenderEvents, updateGoogleTokenGmail } from "./calendarReducer";
import "../calendarModule/calender.css";
import store from "../../../../store";
import { Button, Snackbar } from "@mui/material";
import { Link, useParams, useLocation } from "react-router-dom";
import { Cookies, CookiesProvider, useCookies } from "react-cookie";
import moment from 'moment'
const AvailableSlots = () => {
    const cookies = new Cookies();
    const getcookies = cookies.getAll();
    console.log("cookies in layout==>", getcookies);
    getcookies.google_calendar_connect_time=parseInt(getcookies.google_calendar_connect_time);
    getcookies.google_calendar_connect_time=moment(getcookies.google_calendar_connect_time).format("DD-MMM-YYYY")
    ;
    const [connectgmail, setconnectgmail] = useCookies(["connectgmail"]);
    const [connectgmailaccount, setconnectgmailaccount] = useState(getcookies.connected_gmail);
    const [googlecaledersynctime, setgooglecaledersynctime] = useState(getcookies.google_calendar_connect_time);


    console.log("getcookies.google_calendar_connect_time",getcookies.google_calendar_connect_time)
    const code = useParams();
    const stroreDispatch = useDispatch();
    var accessCode = "";
    var location = useLocation();
    useEffect(() => {
        if (location.pathname != "/calendar-management/available-slots") {
            // console.log("09090900", location);
            accessCode = code.code + "/" + code.secrect;
            console.log("AvailableSlots codes", accessCode);
            var requestDataforgoogleapi = {
                "user_id":getcookies.userInfo._id,
                "code": accessCode
            }
            stroreDispatch(updateGoogleTokenGmail(requestDataforgoogleapi));

        }
    }, []);

    const [calenderEventsData, setcalenderEventsData] = useState([]);
    const [loaderFlag, setloaderFlag] = useState(true);
    var requestData = {
        source: "events_eventdayarr_view",
        condition: {},
        token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTE4MjE0NTIsImlhdCI6MTY1MTczNTA1Mn0.MnKAFEhTHj4K51EXwKuL-0yRK0-AqyoCw5gkX23POpM",
    };
    useEffect(() => {
        stroreDispatch(fetchCalenderEvents(requestData));
    }, []);
    store.subscribe(() => {
        const storeData = store.getState();
        setcalenderEventsData(storeData.calenderEventsStore.calenderEventsData);
        setloaderFlag(storeData.calenderEventsStore.loading);
        console.log("[]][]]][", storeData);
        if(storeData.calenderEventsStore.syncGoogleAcountData!={} && storeData.calenderEventsStore.syncGoogleAcountData.connected_gmail!=undefined){
          setconnectgmailaccount(storeData.calenderEventsStore.connectedgmail);
          var gooleconnecttime=storeData.calenderEventsStore.syncGoogleAcountData.google_calendar_connect_time;
          gooleconnecttime=parseInt(storeData.calenderEventsStore.syncGoogleAcountData.google_calendar_connect_time)
          gooleconnecttime=moment(storeData.calenderEventsStore.syncGoogleAcountData.google_calendar_connect_time).format("DD-MMM-YYYY");
          console.log("gooleconnecttime",gooleconnecttime)
          setgooglecaledersynctime(storeData.calenderEventsStore.gooleconnecttime);
          setconnectgmail('connected_gmail',storeData.calenderEventsStore.connectedgmail, { path: '/' });
        }
    });

    useEffect(() => {
        console.log("121", calenderEventsData);

    }, [calenderEventsData]);
    return (
        <div className="mainContentWrapper form">
      {loaderFlag == true ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        ""
      )}

      <div className="ContentWrapper">
        <div className="Formdiv">
          {/* {/ Login Form Start Here /} */}
          <div className="FormBlock">
            {/* {/ connected email section /} */}
            <div className="connectedemail">
              <h2>Calender Management available slots</h2>
              <div className="calenderinfo">
                <p>
                  CONNECTED WITH GOOGLE CALENDAR <br/> LAST UPDATED ON:{googlecaledersynctime} <br/>
                  EMAIL CONNECTED: {connectgmailaccount}
                </p>
              </div>
            </div>
            <div className="buttonsetToolbar">
              <Button variant="contained">
                <Link to="/calendar-management-createEvent">
                  Create New Availability
                </Link>
              </Button>
              <Button variant="contained">
                <Link to="/calendar-management-event-listing">
                  Availability List
                </Link>
              </Button>
              <Button
                href="https://d0fw1rgejc.execute-api.us-east-2.amazonaws.com/production/sync-calendar"
                variant="contained"
              >
                Update Google Account
              </Button>
            </div>

            {/* {/ Form Section Starts /} */}
            <div className="calender_body formCls">
              {calenderEventsData != undefined &&
              calenderEventsData.length > 0 ? (
                calenderEventsData.map((events, index) => {
                  return (
                    <div key={index} className="calender_block">
                      <h2>{events.event_title}</h2>
                      <p>
                        <span>Date :</span> {events.startdate}
                      </p>
                      <p>
                        <span>Slot :</span> {events.slot} -{" "}
                        {events.slot_end_time}
                      </p>
                      <p>
                        <span>Timezone :</span> {events.timezoneName}
                      </p>
                    </div>
                  );
                })
              ) : (
                <p>No Records Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    );
};
export default AvailableSlots;

