
import React from "react";
import './index.css';
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { Cookies, CookiesProvider } from "react-cookie"

/*****Start Backend Route Links******** */
import BackendLayout from "./Components/Backend/BackendLayout";
import Login from "./Components/Backend/Pages/Login/Login";
import CreateEvent from "./Components/Backend/Pages/calendarModule/createEvent";
import AvailableSlots from "./Components/Backend/Pages/calendarModule/availableSlots"
import { setLogedinUserInfo } from "./Components/Backend/Pages/Login/Login_reducer";
import EventListing from "./Components/Backend/Pages/calendarModule/eventListing";
import NoPage from "./Components/Common/nopage";

/*****End Backend Route Links******** */

const cookies = new Cookies();
console.log("cookies in layout==>", cookies.getAll());

const getcookies = cookies.getAll();
console.log("getcookies from index==>", getcookies);
if (getcookies !== null && getcookies !== undefined && Object.keys(getcookies).length > 0) {
  console.log('getcookies==>', getcookies)
  store.dispatch(setLogedinUserInfo(getcookies));
}

function App() {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            {/* BackendLayout*/}
            <Route path="/" element={<BackendLayout />}>
              <Route index element={<Login />} />
              <Route path="calendar-management-createEvent" element={<CreateEvent />} />
              <Route path="calendar-management-event-listing" element={<EventListing />} />
              <Route path="calendar-management-available-slots" element={<AvailableSlots />} />
              <Route path="calendar-management/available-slots-sync-google/:code/:secrect" element={<AvailableSlots />} />
              <Route path="*" element={<NoPage />} />{" "}
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </CookiesProvider>
  );
}

const container = document.getElementById("root")
const root = createRoot(container)
root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
