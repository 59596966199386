import React, { useEffect } from 'react'
import { InputLabel, TextField, Icon, FormControl } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';


export const Text = ({ fielddata, value, setValue, getValues, errors, clearErrors }) => {

  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, [])


  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}
      {/* FIELD */}
      <FormControl>
        <TextField
          type={(fielddata.inputType !== undefined || fielddata.inputType !== "") ? fielddata.inputType : "text"}
          value={getValues(fielddata.name) ? getValues(fielddata.name) : ""}
          onChange={(e) => {
            if (fielddata.inputType === "number") {
              setValue(fielddata.name, Number(e.target.value));
            } else {
              setValue(fielddata.name, e.target.value);
            }
            clearErrors(fielddata.name); // Clearing errors on focus or change
          }}
          label={fielddata.label}
          error={
            Object.keys(errors).length > 0 && errors[fielddata.name] != null
          }
          helperText={errors[fielddata.name] && errors[fielddata.name].type ? fielddata.errorMessage[errors[fielddata.name].type] : ""}
          InputProps={fielddata.adornments !== undefined ? (fielddata.adornments.position === "start" ? {
            startAdornment: (
              <InputAdornment position={fielddata.adornments.position}>
                <div dangerouslySetInnerHTML={{ __html: fielddata.adornments.content }}></div>
              </InputAdornment>
            ),
          } : {
            endAdornment: (
              <InputAdornment position={fielddata.adornments.position}>
                <div dangerouslySetInnerHTML={{ __html: fielddata.adornments.content }}></div>
              </InputAdornment>
            ),
          }
          ) : {}
          }
        />
      </FormControl>

    </div>
  )
}