import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

////////////////////////////////// Add Data into DB //////////////////////////////////////////////
export const addDataIntoDB = createAsyncThunk("post/db", async ({ data, url, endPoint, endPointBodyWrapper = "data" }) => {
  console.log("form reducer++++++++++++++", data, url, endPoint, endPointBodyWrapper);
  if (endPointBodyWrapper === "" || endPointBodyWrapper == null) {
    endPointBodyWrapper = "data";
  }

  const requestOptions = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify({ [endPointBodyWrapper]: data }),
  }
  const response = await fetch(`${url}${endPoint}`, requestOptions);
  const resResult = await response.json();
  console.log("RESPONSE+++++++++++", resResult);
  resResult.fotmId = data.formId;
  return resResult;
})

////////////////////////////////// Fetch Auto Complete List //////////////////////////////////////
export const fetchAutoCompleteList = createAsyncThunk("autoComplete/list", async ({ BASE_URL, reqBody, query, autoCompleteId }) => {
  console.log('query+++++++++++++++++++++', query, reqBody, BASE_URL, autoCompleteId);
  const requestOptions = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(reqBody),
  }
  const res = await fetch(`${BASE_URL}/api4/bio-new-patient-list`, requestOptions);

  const resResult = await res.json();

  resResult.autoCompleteId = autoCompleteId;

  return resResult;
})


const formSlice = createSlice({
  name: "users",
  initialState: {
    formFieldsData: {},
    formData: {},
    formError: {},
    autoCompleteList: {},
    loading: {},
    formSubmissionState: {},
    editorData: {}
  },
  reducers: {
    setFormSubmissionStateStore(state, action) {
      console.log(action)
      state.formSubmissionState[action.payload] = 0;
    },
    setEditorData(state, action) {
      if (state.editorData[action.payload.formId] === undefined) {
        state.editorData[action.payload.formId] = {};
      }
      state.editorData[action.payload.formId][action.payload.fieldId] = action.payload.tempVar;
    },
    updateFormFieldsData(state, action) {
      console.log(action.payload);
      const temp = state.formFieldsData[action.payload.formId].fields.find(cur => cur.id === action.payload.fielddata.id);
      console.log(temp);
      if (temp) {
        temp.defaultValue = "<p>TEST</p>"
      }
      if (temp && temp.defaultValue !== action.payload.tempVar) {
        temp.defaultValue = action.payload.tempVar;
      }
    },
    setFormFieldsData(state, action) {
      console.log(action.payload);
      state.formFieldsData[action.payload.id] = action.payload;
    },
    setFormData(state, action) {
      // Defining new field against formId in error object if undefined
      if (state.formError[action.payload.formId] === undefined) {
        state.formError[action.payload.formId] = {};
      }


      //////////////////////////////////// Check if last name is valid //////////////////////////
      // if (action.payload.formId === 'userForm' && action.payload.lastname !== "Das") {
      //   // Assigning error msg against field name if undefinded 
      //   if (state.formError[action.payload.formId].lastname === undefined) {
      //     state.formError[action.payload.formId].lastname = 'Lastname is not valid';
      //   }
      // } else {
      //   // Deleting error field and msg against id
      //   delete state.formError[action.payload.formId].lastname;
      // }

      //////////////////////////////////// Setting Formdata into the state //////////////////////
      // Putting data inside the formData aginst formId
      state.formData[action.payload.formId] = action.payload;
    }
  },
  extraReducers: {
    //////////////////////////// Fetch Auto Complete List /////////////////////////////////////
    [fetchAutoCompleteList.pending]: (state, action) => {
      state.loading[action.meta.arg.autoCompleteId] = true;
    },
    [fetchAutoCompleteList.fulfilled]: (state, action) => {
      state.loading[action.payload.autoCompleteId] = false;
      console.log(action.payload);
      const initialResult = [];
      const result = action.payload.results.res.reduce((acc, eachResult) => {
        acc.push({ label: eachResult.coloredstatus });
        return acc;
      }, initialResult);
      state.autoCompleteList[action.payload.autoCompleteId] = result;
    },
    [fetchAutoCompleteList.rejected]: (state, action) => {
      state.loading[action.meta.arg.autoCompleteId] = false;
    },
    ///////////////////////////// Add Data into DB ////////////////////////////
    [addDataIntoDB.pending]: (state, action) => {
      console.log(action);
      state.formSubmissionState[action.meta.arg.data.formId] = 1;
    },
    [addDataIntoDB.fulfilled]: (state, action) => {
      state.formSubmissionState[action.payload.fotmId] = 2;
    },
    [addDataIntoDB.rejected]: (state, action) => {
      console.log(action);
      state.formSubmissionState[action.meta.arg.data.formId] = 3;
    },
  },
});

export const { setFormData, setFormFieldsData, updateFormFieldsData, setEditorData, setFormSubmissionStateStore } = formSlice.actions;

export default formSlice.reducer;
