import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import FilterListIcon from '@mui/icons-material/FilterList';
import ReplayIcon from '@mui/icons-material/Replay';
import { visuallyHidden } from '@mui/utils';
import { Button, LinearProgress, Pagination, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import store from '../store';
import { deleteSingleTableData, fetchTableCount, fetchTableList, setTableData } from './listReducer';
function createData(name, calories, fat, carbs, protein) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
    };
}
var headersArray = [];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Dessert (100g serving)',
    },
    {
        id: 'calories',
        numeric: true,
        disablePadding: false,
        label: 'Calories',
    },
    {
        id: 'fat',
        numeric: true,
        disablePadding: false,
        label: 'Fat (g)',
    },
    {
        id: 'carbs',
        numeric: true,
        disablePadding: false,
        label: 'Carbs (g)',
    },
    {
        id: 'protein',
        numeric: true,
        disablePadding: false,
        label: 'Protein (g)',
    },
];

function EnhancedTableHead(props) {
    console.log("headersArray", headersArray);
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, tableData, setPage, setOrder } =
        props;
    const createSortHandler = (property, tableData, orderBy) => (event) => {
        console.log("EVENT+++++ON SORT++", event.target, event.target.classList.contains("MuiTableSortLabel-iconDirectionDesc"), event.target.classList.contains("MuiTableSortLabel-iconDirectionAsc"), property, tableData,);
        console.log("SORT++++++++++++++++++++++")
        if (tableData.reqBody.sort.type === "desc") {
            console.log("DESC_________________");
            tableData.reqBody.sort.type = "asc"
        } else {
            tableData.reqBody.sort.type = "desc"
        }
        if (property !== orderBy) {
            console.log("CHANGE NODE++++++++++========");
            tableData.reqBody.sort.type = "desc";
        }
        const newSortField = property;
        tableData.reqBody.sort.field = newSortField;
        tableData.reqBody.condition.skip = 0;
        onHandleReload(tableData);
        onRequestSort(event, property);

        setOrder(tableData.reqBody.sort.type);
        setPage(0);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headersArray.map((headCell) => (
                    <TableCell
                        key={headCell.val + headCell.name}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}

                    >
                        {headCell.name}
                        {tableData.sortFields.includes(headCell.val) && (
                            <TableSortLabel
                                active={orderBy === headCell.val}
                                // active={true}
                                direction={orderBy === headCell.val ? order : 'asc'}
                                onClick={createSortHandler(headCell.val, tableData, orderBy)}
                            >
                                {orderBy === headCell.val ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        )}

                    </TableCell>
                ))}
                <TableCell padding='normal' align='center'>
                    Actions
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const onHandleFilter = () => {
    console.log("FILTERRRRRRRRRRR");
}

const onHandleReload = (tableData) => {
    console.log("Reload");
    store.dispatch(fetchTableList({ BASE_URL: tableData.api.url, endPoint: tableData.api.endPoint, reqBody: tableData.reqBody, tableId: tableData.tableId }))
}

/////////////////// On Delete List Data //////////////////
const onHandleDelete = (row, tableData) => {
    console.log("DELETE DATA+++++++", row);
    store.dispatch(deleteSingleTableData({ id: row._id, BASE_URL: tableData.api.url, endPoint: tableData.api.deleteSingleUserEndpoint, reqBody: tableData.deleteSingleUserReqBody, tableId: tableData.tableId }));
}


const EnhancedTableToolbar = (props) => {
    const { numSelected, tableData } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {tableData.tableTitle}
                </Typography>
            )}

            {numSelected > 0 && (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            )}
            {tableData.showFilter && (
                <Tooltip title="Filter list">
                    <IconButton onClick={onHandleFilter}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
            {tableData.showReload && (
                <Tooltip title="Reload">
                    <IconButton onClick={() => onHandleReload(tableData)}>
                        <ReplayIcon />
                    </IconButton>
                </Tooltip>
            )}

        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function ListingTable({ tableData, dataset, modifyHeaders }) {
    const [rows, setRows] = React.useState([]);
    console.log("in listing table ", dataset);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setTimeout(() => {
            if (dataset.length > 0) {
                dispatch(setTableData({ dataset, tableId: tableData.tableId }));
            }
            dispatch(fetchTableCount({ BASE_URL: tableData.api.url, endPoint: tableData.api.endPoint, reqBody: tableData.reqBody, tableId: tableData.tableId }));
        }, 100)

    }, [])
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(tableData.reqBody.sort.field);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [modifyTableHeaders, setmodifyTableHeaders] = React.useState(modifyHeaders.length > 0 ? modifyHeaders : []);
    const [loading, setLoading] = React.useState(false);
    const [rowCount, setRowCount] = React.useState(dataset.length);

    //   setmodifyTableHeaders([...modifyTableHeaders])
    console.log("modifyTableHeaders++", modifyTableHeaders);
    headersArray = modifyHeaders;
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    //////////////////////////////////// ON PAGE CHANGE /////////////////////////////////////////////
    const handleChangePage = (event, newPage) => {
        console.log("NEW PAGE_________________--", newPage);
        let newSkip = null;
        if (tableData.paginationType === "stackPagination") {
            newSkip = (tableData.reqBody.condition.limit * (newPage - 1));
            setPage(newPage - 1);
        } else {
            newSkip = (tableData.reqBody.condition.limit * (newPage));
            setPage(newPage);
        }
        console.log("NEW SKIP+++++++=",)
        const reqBody = tableData.reqBody;
        reqBody.condition.skip = newSkip;

        onHandleReload(tableData);

    };
    ////////////////////////////////////// ON PAGE ROWS COUNT CHANGE //////////////////////////////////
    const handleChangeRowsPerPage = (event) => {

        const newLimit = event.target.value;
        setRowsPerPage(newLimit);
        const reqBody = tableData.reqBody;
        reqBody.condition.limit = newLimit;
        reqBody.condition.skip = 0;

        onHandleReload(tableData);
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    store.subscribe(() => {
        const storeData = store.getState();
        // console.log("_+++++++++++++++++++++++++=", storeData.formSlice.tableData[tableId]);
        setRows(storeData.tableSlice.tableData[tableData.tableId]);
        setLoading(storeData.tableSlice.loading[tableData.tableId]);
        setRowsPerPage(tableData.reqBody.condition.limit);
        setOrderBy(tableData.reqBody.sort.field);
        setOrder(tableData.reqBody.sort.type);
        setPage(parseInt(tableData.reqBody.condition.skip / tableData.reqBody.condition.limit));

        if (storeData.tableSlice.count[tableData.tableId] !== undefined) {
            setRowCount(storeData.tableSlice.count[tableData.tableId]);
        }
    })
    React.useEffect(() => {
        console.log("rowsPerPage++++++++++++==", rowsPerPage)
    }, [rowsPerPage])

    return (
        <div className={"muiTable " + tableData.tableId}>
            {loading && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar numSelected={selected.length} tableData={tableData} />
                    {rows.length > 0 && (
                        <>
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        tableData={tableData}
                                        setPage={setPage}
                                        setOrder={setOrder}
                                    />
                                    <TableBody>
                                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                                        {stableSort(rows, getComparator(order, orderBy))
                                            .slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row._id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => handleClick(event, row._id)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row._id}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell>
                                                            <Checkbox
                                                                color="primary"
                                                                checked={false}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        {headersArray.map((data, index) => (
                                                            <TableCell
                                                                key={row._id + "cell" + row[data.val] + index}
                                                                title={data.name}
                                                            // align={headCell.numeric ? 'right' : 'left'}
                                                            // padding={headCell.disablePadding ? 'none' : 'normal'}

                                                            >
                                                                {row[data.val]}
                                                            </TableCell>
                                                        ))}
                                                        <TableCell align='center'>
                                                            {
                                                                tableData.buttons !== undefined && tableData.buttons.length > 0 && tableData.buttons.map((button, index) => {
                                                                    if (button.type === "delete") {
                                                                        return (
                                                                            <Tooltip key={index} className={button.name} title={button.name}>
                                                                                <IconButton onClick={() => onHandleDelete(row, tableData)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )
                                                                    }
                                                                    if (button.type === "edit") {
                                                                        return (
                                                                            <Tooltip key={index} className={button.name} title={button.name}>
                                                                                <IconButton >
                                                                                    <EditIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )
                                                                    }
                                                                    if (button.type === "view") {
                                                                        return (
                                                                            <Tooltip key={index} className={button.name} title={button.name}>
                                                                                <IconButton>
                                                                                    <PreviewIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )
                                                                    }

                                                                })
                                                            }

                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {/* {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )} */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {tableData.paginationType === "stackPagination" ? (
                                <Stack spacing={2} >
                                    <Pagination
                                        count={Math.round(rowCount / rowsPerPage)}
                                        variant="outlined"
                                        shape="rounded"
                                        page={page + 1}
                                        onChange={handleChangePage}
                                        sx={{ display: "flex", justifyContent: "center", margin: "20px 0px" }}
                                    />
                                </Stack>
                            ) : (
                                <TablePagination
                                    rowsPerPageOptions={tableData.rowsPerPageOptions}
                                    component="div"
                                    count={rowCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            )}
                        </>
                    )}


                </Paper>
                {/* <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label="Dense padding"
                /> */}
            </Box>
            {loading && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
        </div>
    );
}
