import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

////////////////////////////////// Fetch Auto Complete List //////////////////////////////////////
export const fetchTableList = createAsyncThunk("table/list", async ({ BASE_URL, endPoint, reqBody, tableId }) => {
  console.log('query+++++++++++++++++++++', endPoint, reqBody, BASE_URL);
  const requestOptions = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(reqBody),
  }
  const res = await fetch(`${BASE_URL}${endPoint}`, requestOptions);

  const resResult = await res.json();
  resResult.tableId = tableId;

  return resResult;
});

/////////////////////////////////// Fetch Table List Count //////////////////////////////////////
export const fetchTableCount = createAsyncThunk("table/count", async ({ BASE_URL, endPoint, reqBody, tableId }) => {
  console.log('query+++++++++++++++++++++', endPoint, reqBody, BASE_URL);
  const requestOptions = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(reqBody),
  }
  const res = await fetch(`${BASE_URL}${endPoint}-count`, requestOptions);

  const resResult = await res.json();
  resResult.tableId = tableId;

  console.log("From Table Reducer -----------", resResult);

  return resResult;
});

////////////////////////////////// Delete Single User form Table //////////////////////////////////
export const deleteSingleTableData = createAsyncThunk("table/delete", async ({ id, BASE_URL, endPoint, reqBody, tableId }) => {
  console.log("From reducer Dlete", id, BASE_URL, endPoint, reqBody, tableId);
  reqBody = { ...reqBody, id: id };
  const requestOptions = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(reqBody),
  }
  const res = await fetch(`${BASE_URL}${endPoint}`, requestOptions);

  const resResult = await res.json();
  resResult.tableId = tableId;
  resResult.id = id;

  console.log("From Table Reducer -----------", resResult);

  return resResult;
})

const tableSlice = createSlice({
  name: "users",
  initialState: {
    tableData: {},
    snackbarMessage: "",
    loading: {},
    count: {},
  },
  reducers: {
    setTableData(state, action) {
      state.tableData[action.payload.tableId] = action.payload.dataset;
    }
  },
  extraReducers: {
    /////////////////////////////////// Fetch Table List ///////////////////////////
    [fetchTableList.pending]: (state, action) => {
      state.loading[action.meta.arg.tableId] = true;
    },
    [fetchTableList.fulfilled]: (state, action) => {
      state.loading[action.payload.tableId] = false;
      if (action.payload.status === "error") {
        state.snackbarMessage = action.payload.msg; ///////////////// Verification Needed ////////////
      }
      if (action.payload.status === "success") {
        state.tableData[action.payload.tableId] = action.payload.results.res;

      }
    },
    [fetchTableList.rejected]: (state, action) => {
      state.loading[action.meta.arg.tableId] = false;
      state.snackbarMessage = "Something Went Wrong";
    },
    ///////////////////////////// Fetch Table Count ////////////////////////////////
    [fetchTableCount.fulfilled]: (state, action) => {
      state.count[action.payload.tableId] = action.payload.count;
    },
    //////////////////////////// Delete Single User ///////////////////////////////
    [deleteSingleTableData.pending]: (state, action) => {
      state.loading[action.meta.arg.tableId] = true;
    },
    [deleteSingleTableData.fulfilled]: (state, action) => {
      state.loading[action.payload.tableId] = false;
      if (action.payload.status === "success") {
        console.log(state.tableData[action.payload.tableId].findIndex(cur => cur._id === action.payload.id));
        state.tableData[action.payload.tableId].splice(state.tableData[action.payload.tableId].findIndex(cur => cur._id === action.payload.id), 1);
        state.count[action.payload.tableId] -= 1;
      }
      if (action.payload.status === "error") {
        state.snackbarMessage = "Something Went Wrong";
      }
    },
    [deleteSingleTableData.rejected]: (state, action) => {
      state.loading[action.meta.arg.tableId] = false;
      state.snackbarMessage = "Something Went Wrong";
    }
  },
});

export const { setTableData } = tableSlice.actions;

export default tableSlice.reducer;
