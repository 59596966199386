import { configureStore } from "@reduxjs/toolkit";
import loginUser from "./Components/Backend/Pages/Login/Login_reducer";
import formSlice from "./form/formReducer";
import tableSlice from "./listing/listReducer";
import calenderEvents from "./Components/Backend/Pages/calendarModule/calendarReducer";
export default configureStore({
    reducer: {
        loginUserKey: loginUser,
        formSlice: formSlice,
        tableSlice:tableSlice,
        calenderEventsStore:calenderEvents
    },
});