import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchCalenderEvents = createAsyncThunk("calendar/availability", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_CALENDER_URL + "calender-management-api/view-event-eventdayarr",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});

export const updateGoogleTokenGmail = createAsyncThunk("calendar/updateGoogleTokenGmail", async (reqBody) => {


  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(reqBody),
  };
  console.log("process.env==>", process.env);
  const response = await fetch(
    process.env.REACT_APP_GOOGLE_CALENDER_URL + "updatetokengmail",
    requestOptions
  );
  const respdata = await response.json();

  console.log("respdata after endpoint fetch==>", respdata);

  return respdata;
});


const calenderEvents = createSlice({
  name: "calender",
  initialState: {
      calenderEventsData:[],
      syncGoogleAcountData:{},
      loading:false
  },
  reducers: {

  },
  extraReducers: {
    [fetchCalenderEvents.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchCalenderEvents.rejected]: (state, action) => {
      state.loading = false;
    },
    [fetchCalenderEvents.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      if (action.payload.status === "success") {
          console.log("action.payload.data",action.payload.data);
        state.calenderEventsData = action.payload.data;
      }
    },
    [updateGoogleTokenGmail.pending]: (state, action) => {
      state.loading = true;
    },
    [updateGoogleTokenGmail.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateGoogleTokenGmail.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "error") {
        state.errorMessage = action.payload.message;
      }
      // if (action.payload.status === "success") {
          console.log("+++++++++++",action.payload);
        state.syncGoogleAcountData = action.payload.data;
        state.connectedgmail = action.payload.connectedgmailforce;
        state.connectedgmail = action.payload.gooleconnecttime;
      // }
    }

  },
});

export const {  } = calenderEvents.actions;


export default calenderEvents.reducer;
