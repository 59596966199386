import { Box, Button, LinearProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './calender.css'
import ListingTable from '../../../../listing/listing'
import { Link } from 'react-router-dom'
import store from '../../../../store'

const EventListing = () => {
  // const [loaderFlag, setloaderFlag] = useState(true);
  // const [eventListingData, seteventListingData] = useState([]);
  const [responseData, setresponseData] = React.useState([])

  store.subscribe(() => {
    const storeData = store.getState()
    // setloaderFlag(storeData.calenderEventsStore.loading);

    // seteventListingData(storeData.calenderEventsStore.calenderEventsData);
  })

  var modifyTableHeaders = [
    { val: '_id', name: '#' },
    { val: 'event_title', name: 'Event Title' },
    { val: 'description', name: ' Decsription' },
    { val: 'schedule', name: 'Schedule' },
    { val: 'event_type', name: 'Event Type' },
    { val: 'status', name: 'Status' },
    { val: 'created_at', name: 'Created by' },
    { val: 'product_id', name: 'Product' },
    { val: 'phone', name: 'phone' }
  ]

  useEffect(() => {
    getdata()
  }, [])

  const tableData = {
    tableId: 'calendarAvailabilityTable',
    tableTitle: 'Calendar Availability Listing',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      endPoint: 'api/availability-list',
    },
    reqBody: {
      source: '',
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
      searchcondition: {},
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
      secret: 'JwX.(1QHj',
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ['event_title', 'description'],
    // paginationType: "stackPagination",
    buttons: [
      {
        type: 'delete',
        label: 'Delete',
        name: 'delete',
      },
      {
        type: 'edit',
        label: 'Edit',
        name: 'edit',
      },
      {
        type: 'view',
        label: 'View',
        name: 'view',
      },
    ],
  }

  const getdata = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(tableData.reqBody),
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'api/availability-list',
      requestOptions,
    )
    // console.log("++++response+++++++",response);

    const respdata = await response.json()
    console.log('++++cv+++++++', respdata)

    if (respdata.status == 'success') {
      setresponseData([...respdata.results.res])
      console.log(
        '=================respdata.results.res===================',
        respdata.results.res,
      )
      console.log('setresponseData', setresponseData)
    }
  }

  return (
    <div className="mainContentWrapper form">
      <div className="ContentWrapper">
        <div className="Formdiv">
          {/* {/ Login Form Start Here /} */}
          <div className="FormBlock">
            <div className="buttonsetToolbar">
              <Button variant="contained">
                <Link to="/calendar-management/manage-calendar/createEvent">
                  Create New Availability
                </Link>
              </Button>
              <Button variant="contained">
                <Link to="/calendar-management/manage-calendar/event-listing">
                  Availability List
                </Link>
              </Button>
              <Button
                href="https://d0fw1rgejc.execute-api.us-east-2.amazonaws.com/production/sync-calendar"
                variant="contained"
              >
                Update Google Account
              </Button>
            </div>

            {/* {/ Lists Section Starts /} */}
            <div className="reactTableWrapper">
              <div className="reactTable">
                {responseData && responseData.length > 0 ? (
                  <ListingTable
                    tableData={tableData}
                    dataset={responseData}
                    modifyHeaders={modifyTableHeaders}
                  />
                ) : (
                  <p>No Records Found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventListing
