import React, { useState } from 'react'
import { Form } from '../../../../form/Form'
import store from '../../../../store';
import { Button, Snackbar } from "@mui/material";
import { Link, useParams, useLocation } from "react-router-dom";
import { Cookies, CookiesProvider } from "react-cookie";
import { useDispatch } from 'react-redux';

const CreateEvent = () => {
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  console.log("cookies in layout==>++++++", getcookies);

  
  const stroreDispatch = useDispatch();
  var accessCode = "";
  var location = useLocation();

  ///////////////////////////// Custom Validation ////////////////////////
  // const isValidLastname = (data) => data === "Das";
  // console.log("process.env",process.env)

  const [item, setItem] = useState({})

  store.subscribe(() => {
    const storeData = store.getState()
    // storeData.loginUserKey.userInfo;
    setItem(storeData.loginUserKey.userInfo)
  })

  const formData = {
    id: 'createEventForm',
    api_url: process.env.REACT_APP_BASE_URL,
    endPoint: 'add-or-update-event-data',
    urlPathOnSuccessfulFormSubmission: '/calendar-management/available-slots',
    fields: [
      //////////////////////////////////// Text Input Event Title ///////////////////////////////
      {
        id: 0,
        label: 'Event Title',
        name: 'event_title',
        className: 'formGroup eventTitle countDiv__column--col12',
        type: 'text',
        errorMessage: {
          required: 'This Field is Required',
          maxLength: 'MaxLength length 20 allowed',
        },
      },

      //////////////////////////////////// Text Area Input Event Details//////////////////////////
      {
        id: 1,
        label: 'Event Details',
        name: 'description',
        className: 'formGroup eventDetails countDiv__column--col12',
        type: 'textarea',
        rows: 4,
        errorMessage: {
          required: 'This Field is Required',
          minLength: 'Minimum length 20 required',
        },
        defaultValue: '',
      },

      //////////////////////////////// Select Event Type/////////////////////////////////////
      {
        id: 2,
        label: 'Event Type',
        name: 'event_type',
        className: 'formGroup eventType countDiv__column--col12',
        type: 'select',
        values: [
          { val: 'Onboarding Call', name: 'Onboarding Call' },
          { val: 'Discovery Call', name: 'Discovery Call' },
          { val: 'Game Plan Call', name: 'Game Plan Call' },
          { val: 'Warranty Call', name: 'Warranty Call' },
        ],
        errorMessage: {
          required: 'This Field is Required',
        },
        defaultView: 'Event Type',
        defaultValue: '',
        sx: { m: 1, minWidth: 120 },
      },

      /////////////////////////////// Date Picker Start Date////////////////////////////////
      {
        id: 3,
        label: 'Start Date',
        name: 'start_date',
        className: 'formGroup datePicker countDiv__column--col6',
        type: 'datePicker',
        errorMessage: {
          required: 'This Field is Required',
        },
        dateFormat: 'dd/MM/yyyy', //////////////////////////// MM should be capital //////////////////
        // defaultValue: new Date().getTime(),
        defaultValue: 'Start Date',
      },

      /////////////////////////////// Date Picker End Date////////////////////////////////
      {
        id: 4,
        label: 'End Date',
        name: 'end_date',
        className: 'formGroup datePicker countDiv__column--col6',
        type: 'datePicker',
        errorMessage: {
          required: 'This Field is Required',
        },
        dateFormat: 'dd/MM/yyyy', //////////////////////////// MM should be capital //////////////////
        // defaultValue: new Date().getTime(),
        defaultValue: 'End Date',
      },

      //////////////////////////////// Single Check box Day ////////////////////////////
      {
        id: 5,
        heading: 'Available Week Days',
        label: 'Sunday',
        name: 'sun',
        className: 'formGroup singleCheckbox countDiv__column--col12',
        hint: 'Sunday',
        type: 'singleCheckbox',
        values: { key: 0, val: 'Sunday' },
        // rules: { required: true },
        errorMessage: {
          required: 'This Field is Required',
        },
        defaultValue: false,
      },
      {
        id: 6,
        label: 'Monday',
        name: 'mon',
        className: 'formGroup singleCheckbox countDiv__column--col12',
        hint: 'Monday',
        type: 'singleCheckbox',
        values: { key: 1, val: 'Monday' },
        // rules: { required: true },
        errorMessage: {
          required: 'This Field is Required',
        },
        defaultValue: false,
      },
      {
        id: 7,
        label: 'Tuesday',
        name: 'tues',
        className: 'formGroup singleCheckbox countDiv__column--col12',
        hint: 'Tuesday',
        type: 'singleCheckbox',
        values: { key: 2, val: 'Tuesday' },
        // rules: { required: true },
        errorMessage: {
          required: 'This Field is Required',
        },
        defaultValue: false,
      },
      {
        id: 8,
        label: 'Wednesday',
        name: 'wed',
        className: 'formGroup singleCheckbox countDiv__column--col12',
        hint: 'Wednesday',
        type: 'singleCheckbox',
        values: { key: 3, val: 'Wednesday' },
        // rules: { required: true },
        errorMessage: {
          required: 'This Field is Required',
        },
        defaultValue: false,
      },
      {
        id: 9,
        label: 'Thursday',
        name: 'thurs',
        className: 'formGroup singleCheckbox countDiv__column--col12',
        hint: 'Thursday',
        type: 'singleCheckbox',
        values: { key: 4, val: 'Thursday' },
        // rules: { required: true },
        errorMessage: {
          required: 'This Field is Required',
        },
        defaultValue: false,
      },
      {
        id: 10,
        label: 'Friday',
        name: 'fri',
        className: 'formGroup singleCheckbox countDiv__column--col12',
        hint: 'Friday',
        type: 'singleCheckbox',
        values: { key: 5, val: 'Friday' },
        // rules: { required: true },
        errorMessage: {
          required: 'This Field is Required',
        },
        defaultValue: false,
      },
      {
        id: 11,
        label: 'Saturday',
        name: 'sat',
        className: 'formGroup singleCheckbox countDiv__column--col12',
        hint: 'Saturday',
        type: 'singleCheckbox',
        values: { key: 6, val: 'Saturday' },
        // rules: { required: true },
        errorMessage: {
          required: 'This Field is Required',
        },
        defaultValue: false,
      },

      //////////////////////////////// Select Time Span/////////////////////////////////////
      {
        id: 12,
        label: 'Time Span',
        name: 'timespan',
        className: 'formGroup timeSpan countDiv__column--col12',
        type: 'select',
        values: [
          { val: 15, name: '15 Min' },
          { val: 30, name: '30 Min' },
          { val: 60, name: '60 Min' },
          { val: 90, name: '90 Min' },
          { val: 120, name: '120 Min' },
        ],
        errorMessage: {
          required: 'This Field is Required',
        },
        defaultView: 'Time Span',
        defaultValue: '',
        sx: { m: 1, minWidth: 120 },
      },

      //////////////////////////////// Select Time Zone/////////////////////////////////////
      {
        id: 13,
        label: 'Time Zone',
        name: 'timezone',
        className: 'formGroup timeZone countDiv__column--col12',
        type: 'select',
        values: [
          { name: 'Alaska Standard Time', val: '-08:00|America/Anchorage' },
          { name: 'India Standard Time', val: '+05:30|Asia/Kolkata' },
          { name: 'Pacific Standard Time', val: '-07:00|America/Los_Angeles' },
          // { name: 'Mountain Standard Time(GMT-06:00)', val: '-06:00|America/Denver' },
          {
            name: 'Mountain Standard Time On DTS (GMT-07:00)',
            val: '-07:00|America/Phoenix',
          },
          { name: 'Central Standard Time', val: '-05:00|America/Chicago' },
          { name: 'Eastern Standard Time', val: '-04:00|America/New_York' },
          { name: 'Hawaii Standard Time', val: '-10:00|Pacific/Honolulu' },
        ],
        errorMessage: {
          required: 'This Field is Required',
        },
        defaultView: 'Time Zone',
        defaultValue: '',
        sx: { m: 1, minWidth: 120 },
      },

      /////////////////////////////////////// Time Picker Start Time/////////////////////////////
      {
        id: 14,
        label: 'Start Time',
        name: 'start_time',
        className: 'formGroup starTime countDiv__column--col6',
        type: 'timePicker',
        errorMessage: {
          required: 'This Field is Required',
        },
        defaultValue: new Date().getTime(),
      },

      /////////////////////////////////////// Time Picker End Time/////////////////////////////
      {
        id: 15,
        label: 'End Time',
        name: 'end_time',
        className: 'formGroup endTime countDiv__column--col6',
        type: 'timePicker',
        errorMessage: {
          required: 'This Field is Required',
        },
        defaultValue: new Date().getTime(),
      },

      //////////////////////////////// Select Product/////////////////////////////////////
      {
        id: 16,
        label: 'Product',
        name: 'product_id',
        className: 'formGroup product countDiv__column--col12',
        type: 'select',
        values: [
          {
            val: 'PECE platform and TM-Flow',
            name: 'PECE platform and TM-Flow',
          },
          {
            val: 'BioEnergetics platform with neoGEN Series',
            name: 'BioEnergetics platform with neoGEN Series',
          },
        ],
        errorMessage: {
          required: 'This Field is Required',
        },
        defaultView: 'Product',
        defaultValue: '',
      },
    ],
    formAdditionalSubmissionData: {
      userid: `${item._id}`,
      username: `${item.firstname} ${item.lastname}`,
      useremail: `${item.email}`,
    },
  }

  return (
    <>
      <div className="mainContentWrapper form">
        <div className="ContentWrapper">
          <div className="Formdiv">
            {/* Login Form Start Here */}
            <div className="FormBlock">
              {/* connected email section */}
              <div className="connectedemail">
                <h2>Calender Management available slots</h2>
                <div className="calenderinfo">
                  <p>
                  CONNECTED WITH GOOGLE CALENDAR <br/> LAST UPDATED ON:{getcookies.google_calendar_connect_time} <br/>
                  EMAIL CONNECTED: {getcookies.connected_gmail}
                  </p>
                </div>
              </div>
              <div className="buttonsetToolbar">
                <Button variant="contained">
                  <Link to="/calendar-management/available-slots">
                    Availability Slots
                  </Link>
                </Button>                
              </div>
              {/* Form Section Starts */}
              <Form formData={formData} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CreateEvent
