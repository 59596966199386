import { FormControlLabel, FormHelperText, InputLabel, Switch } from '@mui/material';
import React, { useEffect } from 'react'

export const SwitchButton = ({ fielddata, value, setValue, getValues, errors, clearErrors }) => {
  ///////////////////////////// Set Default Value //////////////////////////
  useEffect(() => {
    if (fielddata.defaultValue)
      setValue(fielddata.name, fielddata.defaultValue);
  }, [])

  return (
    <div className={fielddata.className}>
      {/* HEADING */}
      {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}

      <FormControlLabel control={
        <Switch
          checked={getValues(fielddata.name) ? getValues(fielddata.name) : false}
          onChange={(e) => {
            setValue(fielddata.name, e.target.checked);
            clearErrors(fielddata.name);
          }}
          inputProps={{ "aria-label": "controlled" }}
        />
      } label={fielddata.labels !== undefined && (getValues(fielddata.name) !== undefined ? (getValues(fielddata.name) ? fielddata.labels[0] : fielddata.labels[1]) : fielddata.labels[1])} />

      {/* ERROR */}
      {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
    </div>
  )
}
